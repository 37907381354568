import React from 'react';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { EnrollmentFormValues } from '../EnrollmentFormValues';

export interface FormTextFieldProps extends FormikProps<EnrollmentFormValues> {
    id: string;
    label: string;
    placeholder: string;
}

export const FormTextField = (props: FormTextFieldProps) => {
    const { id, label, placeholder, values, handleChange, handleBlur, errors, touched } = props;

    return (
        <div
            className={classNames('field', {
                'spot-form--error': errors[id] && touched[id],
            })}
        >
            <label htmlFor={id} style={{ display: 'block' }}>
                {label}
            </label>
            <input
                id={id}
                placeholder={placeholder}
                type="text"
                value={values[id]}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classNames('text-input', 'spot-form__input', {
                    error: errors[id] && touched[id],
                })}
            />
            {errors[id] && touched[id] && (
                <span className="spot-form__field-error" id="first-name-error" role="alert">
                    <span className="spot-form__field-error-text">{errors[id]}</span>
                </span>
            )}
        </div>
    );
};
