import * as React from 'react';
import { SpotSvg } from './SpotSvg';

interface SpotSearchbarProps {
    value?: any;
    onChange?: (value) => void;
    onSubmit: (value) => void;
    onClick?: () => void;
    isDisabled?: boolean;
    isSelfControlled: boolean;
}

interface SpotSearchbarState {
    currentValue: string;
}

export class SpotSearchbar extends React.Component<SpotSearchbarProps, SpotSearchbarState> {
    static defaultProps = {
        isSelfControlled: true,
    };

    state = {
        currentValue: this.props.value || '',
    };

    get value(): string {
        return this.state.currentValue;
    }

    onInputChanged = e => {
        const newValue = e.target.value;
        this.setState({ currentValue: newValue });
        this.props.onChange && this.props.onChange(newValue);
    };

    onInputKeyPress = e => {
        if (e.key === 'Enter') {
            const { currentValue } = this.state;
            this.props.onSubmit(currentValue);
        }
    };

    submit = () => {
        const { currentValue } = this.state;
        this.props.onSubmit(currentValue);
    };

    render() {
        const value = this.props.isSelfControlled ? this.state.currentValue : this.props.value;
        return (
            <div className="spot-form spot-search-bar" style={{ margin: '10px' }}>
                <input
                    className="spot-form__input spot-search-bar__input"
                    type="text"
                    autoComplete="off"
                    id="search-bar-text"
                    value={value}
                    onChange={this.onInputChanged}
                    onKeyPress={this.onInputKeyPress}
                    onClick={this.props.onClick}
                    disabled={this.props.isDisabled}
                />

                {this.props.children && this.props.children}

                <button className="spot-search-bar__search-button" type="button" onClick={this.submit} disabled={this.props.isDisabled}>
                    <SpotSvg className={'spot-icon'} icon="search" width="12px" height="12px" />
                </button>
            </div>
        );
    }
}
