import { DropdownOptionInterface } from '../fields/FormDropdownField';
import { TFunction } from 'i18next';

// Enrollment API requires values to be exactly 3 characters
export const getCountryOptions = (translate: TFunction): DropdownOptionInterface[] => [
    { label: translate('common:selectOption', 'Select an option'), value: '' },
    { label: 'United States of America', value: 'USA' },
    { label: 'Canada', value: 'CA' },
    { label: 'Australia', value: 'AUS' },
    { label: 'New Zealand', value: 'NZ' },
];
