export const environment = {
    get isDev() {
        return ['LOCAL', 'DEV', 'DEVELOPMENT'].indexOf(this.APP_ENV) !== -1;
    },
    get isLocal() {
        return this.APP_ENV === 'LOCAL';
    },
    APP_ENV: (process.env.REACT_APP_APP_ENV || 'DEV').toUpperCase(),
    ENROLLMENT_API: {
        baseUrl: process.env.REACT_APP_ENROLLMENT_BASE_API_URL,
    },
    LOGGING: {
        logLevel: 'error',
    },
    dateFormat: 'lll',
};
