import React from 'react';
import { FormTitle } from '../../components/FormTitle';
import { FormTextField } from '../../components/fields/FormTextField';
import { Col, Row } from 'react-bootstrap';
import { FormikProps } from 'formik';
import { EnrollmentFormValues } from '../../components/EnrollmentFormValues';
import { FormRadioGroup } from '../../components/fields/FormRadioGroup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { EnrollmentPageStore } from '../EnrollmentPageStore';
import { useStore } from '../../hooks';
import { Stores } from '../stores';

export const ContactStep = observer((props: FormikProps<EnrollmentFormValues>) => {
    const enrollmentPage = useStore<EnrollmentPageStore>(Stores.Enrollment);
    const { t } = useTranslation();

    const configurableQuestions =
        enrollmentPage.questions.map((question, index) => (
            <Row key={`custom-question-${index}`}>
                <Col xs="12">
                    <FormRadioGroup
                        {...props}
                        id={question.questionText}
                        label={question.questionText}
                        options={question.options
                            .slice()
                            .sort((a, b) => a.displayOrder - b.displayOrder)
                            .map(option => ({ label: option.text, value: option.value }))}
                    />
                </Col>
            </Row>
        )) || [];

    return (
        <>
            <FormTitle text={t('contact:title', 'Who is the technical contact at the practice for any connection issues?')} image={'contact.svg'} />

            <Row>
                <Col xs="4">
                    <FormTextField {...props} id="firstName" label={t('contact:firstName', 'First Name')} placeholder="" />
                </Col>
                <Col xs="4">
                    <FormTextField {...props} id="lastName" label={t('contact:lastName', 'Last Name')} placeholder="" />
                </Col>
                <Col xs="4">
                    <FormTextField {...props} id="contactTitle" label={t('contact:contactTitle', 'Position/Title')} placeholder="" />
                </Col>
            </Row>
            <Row>
                <Col xs="4">
                    <FormTextField {...props} id="phoneNumber" label={t('contact:phone', 'Phone Number')} placeholder="" />
                </Col>
                <Col xs="8">
                    <FormTextField {...props} id="email" label={t('contact:email', 'Email Address')} placeholder="" />
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <div className="field-group-title">{t('contact:dataConnection', 'Data Connection Questions')}</div>
                </Col>
            </Row>
            {configurableQuestions}
        </>
    );
});
