import BasicLayout from '../layout/basic/BasicLayout';
import EnrollmentForm from '../components/EnrollmentForm';

const baseRoute = {
    path: null,
    isSecured: false,
    Layout: BasicLayout,
    role: '*',
    Component: null,
    config: undefined,
};

export default [
    {
        ...baseRoute,
        path: '/',
        exact: true,
        Component: EnrollmentForm,
    },
];
