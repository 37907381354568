import { ApiErrorType } from './ApiError';
import { SubmitApiError } from './SubmitApiError';
import { SubmittedEnrollmentErrorsDto } from '../dtos';

export class SubmitBadRequestApiError extends SubmitApiError {
    errors: SubmittedEnrollmentErrorsDto;

    constructor(message: string, errors: SubmittedEnrollmentErrorsDto) {
        super(message);
        this.type = ApiErrorType.SUBMIT_BADREQUEST;
        this.errors = errors;
    }
}
