import React from 'react';
import classNames from 'classnames';

export interface EnrollmentFormStepTrackerInterface {
    // currentStep should start at 0
    currentStep: number;
    totalSteps: number;
}

export const EnrollmentFormStepTracker = ({ currentStep, totalSteps }: EnrollmentFormStepTrackerInterface) => {
    const steps: JSX.Element[] = [];

    for (let step = 0; step < totalSteps; step++) {
        steps.push(
            <span key={`step-tracker-${step}`} className={classNames('step-tracker-dot', { 'step-tracker-dot--current': currentStep === step })} />,
        );
    }

    if (totalSteps > 1) {
        return <div className="step-tracker">{steps}</div>;
    } else {
        return null;
    }
};
