import React from 'react';
import { SpotLoading } from '@enrollment/spot';
import classNames from 'classnames';

export interface LoadingProps {
    text?: string;
    asOverlay?: boolean;
}

export const Loading = ({ text = '', asOverlay = false }: LoadingProps) => {
    return (
        <div className={classNames('loading', { 'loading-overlay': asOverlay, container: !asOverlay })}>
            <SpotLoading size="large" />
            <span className="loading-text">{text}</span>
        </div>
    );
};
