import { Container } from 'typedi';
import * as mobxLogger from 'mobx-logger';
import { environment } from '../environment/environment';
import { BaseHttp } from './http/BaseHttp';
import { RootStore } from '../store/RootStore';

if (environment.isDev) {
    mobxLogger.enableLogging({
        action: true,
        compute: true,
        reaction: true,
        transaction: true,
    });
}

export function boot_di() {
    Container.set('http', new BaseHttp(environment.ENROLLMENT_API, Container.get(RootStore).getAuthToken));
}

export function addPageStore<T>(identifier, TCreator: new (...args: any[]) => T) {
    Container.set(TCreator as any);
    const pageStore = Container.get(TCreator);
    const rootStore = Container.get(RootStore);
    rootStore.pages[identifier] = pageStore;
}

const ioc = Container;
export default ioc;
