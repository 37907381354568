import * as React from 'react';
import classNames from 'classnames';
import { SpotFieldError } from './SpotFieldError';

export interface SpotRadioGroupProps {
    error?: any;
    isHorizontal?: boolean;
}

export class SpotRadioGroup extends React.PureComponent<SpotRadioGroupProps & React.HTMLProps<HTMLFieldSetElement>> {
    render() {
        const { className, error, children, isHorizontal, ...rest } = this.props;
        return (
            <fieldset
                className={classNames('spot-form__radio-group', { isHorizontal: 'spot-form__radio-group--horizontal' }, className)}
                role="radiogroup"
                {...rest}
            >
                <div className="spot-form__radio-group-inner">{children}</div>
                {error && <SpotFieldError meta={error} />}
            </fieldset>
        );
    }
}
