import * as React from 'react';
import classNames from 'classnames';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { SpotSvg } from '../SpotSvg';

interface ReactBootstrapDaterangepickerProps {
    alwaysShowCalendars?: any;
    applyClass?: any;
    autoApply?: any;
    autoUpdateInput?: any;
    buttonClasses?: any;
    cancelClass?: any;
    dateLimit?: any;
    drops?: any;
    endDate?: any;
    isCustomDate?: any;
    isInvalidDate?: any;
    linkedCalendars?: any;
    locale?: any;
    maxDate?: any;
    minDate?: any;
    opens?: any;
    parentEl?: any;
    ranges?: any;
    showCustomRangeLabel?: any;
    showDropdowns?: any;
    showISOWeekNumbers?: any;
    showWeekNumbers?: any;
    singleDatePicker?: any;
    startDate?: any;
    template?: any;
    timePicker?: any;
    timePicker24Hour?: any;
    timePickerIncrement?: any;
    timePickerSeconds?: any;
    onApply?: (event, data) => void;
}

export interface SpotDatePickerProps extends ReactBootstrapDaterangepickerProps {
    bordered?: boolean;
    fixedWidth?: string;
    children: JSX.Element;

    [key: string]: any;
}

export interface SpotDatePickerState {
    isOpen?: boolean;
    contentWidth?: number;
}

export class SpotDatePicker extends React.Component<SpotDatePickerProps, SpotDatePickerState> {
    static defaultProps = {
        bordered: false,
        noPrompt: false,
    };

    state: SpotDatePickerState = {
        isOpen: false,
        contentWidth: 0,
    };
    private widthRef: any;

    constructor(props) {
        super(props);
        this.widthRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.contentWidth !== this.widthRef.current.clientWidth) {
            this.setState({ contentWidth: this.widthRef.current.clientWidth });
        }
    }

    render() {
        const { fixedWidth, children, className, ...rest } = this.props;
        return (
            <DateRangePicker
                buttonClasses={['spot-button', 'spot-button--secondary', 'spot-button--small', 'date-picker-buttons']}
                opens={'right'}
                {...rest}
            >
                <div
                    className={classNames('dropdown-wrapper-outer', { 'dropdown-bordered': this.props.bordered }, className)}
                    style={{ width: !!this.state.contentWidth ? this.state.contentWidth : 'auto' }}
                >
                    <span className={`dropdown-wrapper-inner${this.state.isOpen ? '-selected' : ''}`} style={{ margin: 0 }}>
                        <span className="dropdown-header" style={{ padding: 0 }} ref={this.widthRef}>
                            <span className={fixedWidth ? 'fixed-width' : ''} style={{ width: this.props.fixedWidth }}>
                                {React.isValidElement(children) ? React.cloneElement(children) : React.createElement(children as any)}
                                <SpotSvg icon="caret-down" width="12px" height="12px" />
                            </span>
                        </span>
                    </span>
                </div>
            </DateRangePicker>
        );
    }
}
