export enum ApiErrorType {
    UNKNOWN,
    SUBMIT,
    SUBMIT_BADREQUEST,
}

export abstract class ApiError {
    type: ApiErrorType;

    message: string;

    protected constructor(message: string) {
        this.message = message;
    }
}
