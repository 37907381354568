import { DropdownOptionInterface } from '../fields/FormDropdownField';

export const StateOptionsUSA: DropdownOptionInterface[] = [
    { label: '', value: '' },
    { label: 'AK', value: 'AK' },
    { label: 'AL', value: 'AL' },
    { label: 'AR', value: 'AR' },
    { label: 'AZ', value: 'AZ' },
    { label: 'CA', value: 'CA' },
    { label: 'CO', value: 'CO' },
    { label: 'CT', value: 'CT' },
    { label: 'DC', value: 'DC' },
    { label: 'DE', value: 'DE' },
    { label: 'FL', value: 'FL' },
    { label: 'GA', value: 'GA' },
    { label: 'HI', value: 'HI' },
    { label: 'IA', value: 'IA' },
    { label: 'ID', value: 'ID' },
    { label: 'IL', value: 'IL' },
    { label: 'IN', value: 'IN' },
    { label: 'KS', value: 'KS' },
    { label: 'KY', value: 'KY' },
    { label: 'LA', value: 'LA' },
    { label: 'MA', value: 'MA' },
    { label: 'MD', value: 'MD' },
    { label: 'ME', value: 'ME' },
    { label: 'MI', value: 'MI' },
    { label: 'MN', value: 'MN' },
    { label: 'MO', value: 'MO' },
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'NC', value: 'NC' },
    { label: 'ND', value: 'ND' },
    { label: 'NE', value: 'NE' },
    { label: 'NH', value: 'NH' },
    { label: 'NJ', value: 'NJ' },
    { label: 'NM', value: 'NM' },
    { label: 'NV', value: 'NV' },
    { label: 'NY', value: 'NY' },
    { label: 'OH', value: 'OH' },
    { label: 'OK', value: 'OK' },
    { label: 'OR', value: 'OR' },
    { label: 'PA', value: 'PA' },
    { label: 'PR', value: 'PR' },
    { label: 'RI', value: 'RI' },
    { label: 'SC', value: 'SC' },
    { label: 'SD', value: 'SD' },
    { label: 'TN', value: 'TN' },
    { label: 'TX', value: 'TX' },
    { label: 'UT', value: 'UT' },
    { label: 'VA', value: 'VA' },
    { label: 'VT', value: 'VT' },
    { label: 'WA', value: 'WA' },
    { label: 'WI', value: 'WI' },
    { label: 'WV', value: 'WV' },
    { label: 'WY', value: 'WY' },
];

export const StateOptionsCA: DropdownOptionInterface[] = [
    { label: '', value: '' },
    { label: 'AB', value: 'AB' },
    { label: 'BC', value: 'BC' },
    { label: 'MB', value: 'MB' },
    { label: 'NB', value: 'NB' },
    { label: 'NL', value: 'NL' },
    { label: 'NS', value: 'NS' },
    { label: 'NT', value: 'NT' },
    { label: 'NU', value: 'NU' },
    { label: 'ON', value: 'ON' },
    { label: 'PE', value: 'PE' },
    { label: 'QC', value: 'QC' },
    { label: 'SK', value: 'SK' },
    { label: 'YT', value: 'YT' },
];

export const StateOptionsAUS: DropdownOptionInterface[] = [
    { label: '', value: '' },
    { label: 'ACT', value: 'ACT' },
    { label: 'NSW', value: 'NSW' },
    { label: 'NT', value: 'NT' },
    { label: 'QLD', value: 'QLD' },
    { label: 'SA', value: 'SA' },
    { label: 'TAS', value: 'TAS' },
    { label: 'VIC', value: 'VIC' },
    { label: 'WA', value: 'WA' },
];

export const StateOptionsNZ: DropdownOptionInterface[] = [
    { label: '', value: '' },
    { label: 'AUK', value: 'AUK' },
    { label: 'BOP', value: 'BOP' },
    { label: 'CAN', value: 'CAN' },
    { label: 'GIS', value: 'GIS' },
    { label: 'HKB', value: 'HKB' },
    { label: 'MBH', value: 'MBH' },
    { label: 'MWT', value: 'MWT' },
    { label: 'NSN', value: 'NSN' },
    { label: 'NTL', value: 'NTL' },
    { label: 'OTA', value: 'OTA' },
    { label: 'STL', value: 'STL' },
    { label: 'TAS', value: 'TAS' },
    { label: 'TKI', value: 'TKI' },
    { label: 'WGN', value: 'WGN' },
    { label: 'WKO', value: 'WKO' },
    { label: 'WTC', value: 'WTC' },
];

export const StateOptions: DropdownOptionInterface[] = [
    { label: '', value: '' },
    { label: 'AK', value: 'AK' },
    { label: 'AL', value: 'AL' },
    { label: 'AR', value: 'AR' },
    { label: 'AZ', value: 'AZ' },
    { label: 'CA', value: 'CA' },
    { label: 'CO', value: 'CO' },
    { label: 'CT', value: 'CT' },
    { label: 'DC', value: 'DC' },
    { label: 'DE', value: 'DE' },
    { label: 'FL', value: 'FL' },
    { label: 'GA', value: 'GA' },
    { label: 'HI', value: 'HI' },
    { label: 'IA', value: 'IA' },
    { label: 'ID', value: 'ID' },
    { label: 'IL', value: 'IL' },
    { label: 'IN', value: 'IN' },
    { label: 'KS', value: 'KS' },
    { label: 'KY', value: 'KY' },
    { label: 'LA', value: 'LA' },
    { label: 'MA', value: 'MA' },
    { label: 'MD', value: 'MD' },
    { label: 'ME', value: 'ME' },
    { label: 'MI', value: 'MI' },
    { label: 'MN', value: 'MN' },
    { label: 'MO', value: 'MO' },
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'NC', value: 'NC' },
    { label: 'ND', value: 'ND' },
    { label: 'NE', value: 'NE' },
    { label: 'NH', value: 'NH' },
    { label: 'NJ', value: 'NJ' },
    { label: 'NM', value: 'NM' },
    { label: 'NV', value: 'NV' },
    { label: 'NY', value: 'NY' },
    { label: 'OH', value: 'OH' },
    { label: 'OK', value: 'OK' },
    { label: 'OR', value: 'OR' },
    { label: 'PA', value: 'PA' },
    { label: 'PR', value: 'PR' },
    { label: 'RI', value: 'RI' },
    { label: 'SC', value: 'SC' },
    { label: 'SD', value: 'SD' },
    { label: 'TN', value: 'TN' },
    { label: 'TX', value: 'TX' },
    { label: 'UT', value: 'UT' },
    { label: 'VA', value: 'VA' },
    { label: 'VT', value: 'VT' },
    { label: 'WA', value: 'WA' },
    { label: 'WI', value: 'WI' },
    { label: 'WV', value: 'WV' },
    { label: 'WY', value: 'WY' },
];

export const StatesByCountry: { [Identifier: string]: DropdownOptionInterface[] } = {
    USA: StateOptionsUSA,
    CA: StateOptionsCA,
    AUS: StateOptionsAUS,
    NZ: StateOptionsNZ,
};
