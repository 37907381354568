import React, { Suspense } from 'react';
import * as styles from './App.module.css';
import { inject, observer } from 'mobx-react';
import EnrollmentRouter from './router/EnrollmentRouter';
import routes from './router/routes';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { RootStore } from './store/RootStore';

@observer
class App extends React.Component<any> {
    async componentDidMount(): Promise<void> {
        await (this.props.rootStore as RootStore).loadUserData();
    }

    render() {
        return (
            <Suspense fallback={null}>
                <div className={styles.enrollmentApp}>
                    <ErrorBoundary>
                        <EnrollmentRouter routes={routes} />
                    </ErrorBoundary>
                </div>
            </Suspense>
        );
    }
}

export default inject('rootStore')(App);
