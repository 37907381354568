import React from 'react';
import { EnrollmentFormStepTracker } from './EnrollmentFormStepTracker';
import { Row, Col } from 'react-bootstrap';
import { FormikProps } from 'formik';
import { EnrollmentFormValues } from './EnrollmentFormValues';
import { useTranslation } from 'react-i18next';

export interface EnrollmentFormFooterProps extends FormikProps<EnrollmentFormValues> {
    brandedImage: string;
    children?: any;
    currentStepIndex: number;
    totalSteps: number;
    backButtonText?: string;
    nextButtonText?: string;
    doneButtonText?: string;
    onNext: (props: FormikProps<EnrollmentFormValues>) => void;
    onBack: (props: FormikProps<EnrollmentFormValues>) => void;
}

export const EnrollmentFormFooter = (props: EnrollmentFormFooterProps) => {
    const { t } = useTranslation();
    const {
        brandedImage,
        children,
        currentStepIndex,
        totalSteps,
        backButtonText = t('common:previous'),
        nextButtonText = t('common:next'),
        doneButtonText = 'Accept',
        onNext,
        onBack,
    } = props;
    const isFinalStep = currentStepIndex + 1 === totalSteps;
    const actionButtonText = isFinalStep ? doneButtonText : nextButtonText;

    return (
        <div className="enrollment-form-footer">
            {children}
            <Row className={'footer-action-row'}>
                <Col xs={2}>
                    {currentStepIndex > 0 && (
                        <button type="button" className="spot-button spot-button--link" onClick={() => onBack(props)}>
                            {backButtonText}
                        </button>
                    )}
                </Col>
                <Col xs={8}>
                    <button type="button" className="spot-button spot-button--primary" onClick={() => onNext(props)}>
                        {actionButtonText}
                    </button>
                </Col>
                <Col xs={2}>
                    <img src={`/assets/img/${brandedImage}`} className={'footer-logo'} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <EnrollmentFormStepTracker currentStep={currentStepIndex} totalSteps={totalSteps} />
                </Col>
            </Row>
        </div>
    );
};
