import * as React from 'react';

export interface SpotInputProps extends React.HTMLProps<HTMLInputElement> {
    name?: string;
    error?: any;
    [prop: string]: any;
}

export interface Automatable {
    automationId?: string;
}

export class BaseSpotComponent<P = {}, S = {}, SS = {}> extends React.PureComponent<P, S, SS> {
    private idSuffix = randomString(4, 5);

    get idProp(): string {
        const { id, name } = this.props as any;
        return id ? `${id}` : `${name}_${this.idSuffix}`;
    }
}

function randomString(min, max): string {
    let iterator;
    const length = Math.floor(Math.random() * (max - min + 1)) + min;
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';

    for (iterator = 0; iterator < length; iterator += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
