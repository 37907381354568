import * as React from 'react';
import classNames from 'classnames';
import {NavLink} from 'react-router-dom';
import {SpotPill} from '../SpotPill';

export class SideNavigationProps {
    menu: { label: string; path: string; iconPath?: string; notificationsCount?: number }[] = [];
    isCollapsed?:boolean;
}

export class SideNavigation extends React.PureComponent<SideNavigationProps> {
    render() {
        const {menu, isCollapsed} = this.props;
        return (
            <ul className={classNames('sidenav-container', 'spot-list-group', {'is-collapsed': isCollapsed})}>
                {menu.map((item, i) => (
                    <li key={i} className="spot-list-group__item ">
                        <NavLink
                            to={item.path}
                            className={classNames('spot-list-group__link', 'spot-link--large')}
                            activeClassName="spot-list-group__item--active"
                        >
                            <span className="spot-list-group__item-label">
                                {item.iconPath && (
                                    <svg className="spot-icon spot-list-group__item-icon">
                                        <title>{item.label}</title>
                                        <use href={item.iconPath}/>
                                    </svg>
                                )}
                                {item.notificationsCount && (
                                    <SpotPill type={'warning'} isSmall={true}>
                                        {item.notificationsCount}
                                    </SpotPill>
                                )}
                                {!isCollapsed && item.label}
                            </span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        );
    }
}
