import Axios, { AxiosInstance } from 'axios';
import { HttpException } from './exceptions';

export interface BaseHttpOptions {
    baseUrl?: string;
    url?: string;
    method?: string;
    data?: any;
    headers?: any;
    params?: any;
    pathParams?: any;
    suppressNotifications?: boolean;
    transformRequest?: any[];
    transformResponse?: any[];
}

export class BaseHttp {
    private client: AxiosInstance;

    constructor(protected globalOptions: BaseHttpOptions = { baseUrl: '/' }, private bearerTokenSelector: () => string | null) {
        this.client = Axios.create({
            baseURL: globalOptions.baseUrl,
        });
    }

    private safelyInterpolateUrl({ pathParams, url }: BaseHttpOptions) {
        if (!url || !pathParams) {
            return url;
        }
        return Object.entries(pathParams).reduce((agg, [key, value]) => agg.replace(`:${key}`, encodeURIComponent(`${value}`)), url);
    }

    /**
     *
     * @param config
     * @return {Promise}
     */
    request(config: BaseHttpOptions = {}) {
        const token = this.bearerTokenSelector();
        config.url = this.safelyInterpolateUrl(config);

        if (token) {
            config.headers = config.headers || {};
            if (!config.headers.Authorization) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }

        const cfg = Object.assign({}, config, {
            headers: Object.assign(config.headers || {}, this.globalOptions.headers || {}),
            params: Object.assign(config.params || {}, this.globalOptions.params || {}),
        });

        return this.client
            .request(cfg)
            .then(response => response.data)
            .catch(e => {
                let status = e.response.status;
                let message = e.response.statusText || e.message;
                if (e.response.data) {
                    message = e.response.data.message ? e.response.data.message.error || e.response.data.message : message;
                    status = e.response.data.status || status;
                }
                return Promise.reject(new HttpException(status, message, e));
            });
    }

    /**
     * @param url
     * @param config
     * @return {Promise}
     */
    get(url, config: BaseHttpOptions = {}) {
        return this.request({ method: 'GET', url, ...config });
    }

    /**
     * @param url
     * @param data
     * @param config
     * @return {Promise}
     */
    post(url, data?: any, config: BaseHttpOptions = {}) {
        return this.request({ method: 'POST', url, ...config, data });
    }
}
