export enum ContactTypes {
    ContractSigner = 'ContractSigner',
    PracticeContact = 'PracticeContact',
    TechnicalContact = 'TechnicalContact',
}

export interface Contact {
    firstName: string | null;
    lastName: string | null;
    jobTitle: string | null;
    phone: string | null;
    email: string | null;
}

export interface PracticeContact extends Contact {
    type: ContactTypes.PracticeContact;
}

export interface TechnicalContact extends Contact {
    type: ContactTypes.TechnicalContact;
}
export interface ContractSigner extends Contact {
    type: ContactTypes.ContractSigner;
}
