import { FormikProps } from 'formik';
import { Component } from 'react';
import { ApiError } from '../services/error';

export class ErrorFocus extends Component<FormikProps<any> & { apiError?: ApiError }> {
    public componentDidUpdate(prevProps: FormikProps<any> & { apiError?: ApiError }) {
        const { isSubmitting, isValidating, errors } = prevProps;
        const keys = Object.keys(errors) || [];
        if (keys.length > 0 && isSubmitting && !isValidating) {
            const selector = `label[for="${keys[0]}"]`;
            const errorElement = document.querySelector(selector) as HTMLElement;
            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (prevProps.apiError && isSubmitting && !isValidating) {
            const selector = `.title-text`;
            const errorElement = document.querySelector(selector) as HTMLElement;
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }
    }

    public render = () => null;
}
