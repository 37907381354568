import { EnrollmentPageStore } from './EnrollmentPageStore';
import { addPageStore } from '../services/ioc';

export enum Stores {
    Enrollment = 'enrollmentPage',
}

export function registerPagesStore() {
    addPageStore(Stores.Enrollment, EnrollmentPageStore);
}
