import { inject } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import BasicLayout from '../layout/basic/BasicLayout';
import { autorun } from 'mobx';

interface ConnectRouterProps {
    routes: any[];
    children?: any;
    routing?: RouterStore;
}

class EnrollmentRouter extends React.Component<ConnectRouterProps, { prevPath: string; prevToken: any }> {
    state = {
        prevPath: 'm2n3b4m5n2b345',
        prevToken: null,
    };

    componentDidMount() {
        const { routing } = this.props;
        autorun(() => {
            const { prevPath } = this.state;
            const newState: any = {};
            const currentPath = (routing as RouterStore).location.pathname;
            if (!(prevPath.includes(currentPath) || currentPath.includes(prevPath)) && currentPath !== prevPath) {
                newState.prevPath = currentPath;
            }
            this.setState(newState);
        });
    }

    render() {
        const { routes, routing } = this.props;
        const routesCmp = routes.map((route, index) => {
            const { Component, config } = route;
            const Layout = route.Layout ? route.Layout : BasicLayout;
            return (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={({ ...args }) => (
                        <Layout {...config}>
                            <Component {...config} {...args} />
                        </Layout>
                    )}
                />
            );
        });
        return <Switch location={(routing as RouterStore).location}>{routesCmp}</Switch>;
    }
}

export default inject((stores: any) => ({
    routing: stores.routing,
}))(EnrollmentRouter);
