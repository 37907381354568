import './index.scss';
import 'reflect-metadata'; // DO NOT REMOVE THIS IMPORT
import { Container } from 'typedi';
import createBrowserHistory from 'history/createBrowserHistory';
import { Provider } from 'mobx-react';
import { syncHistoryWithStore } from 'mobx-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router';
import App from './App';
import { RootStore } from './store/RootStore';
import { registerPagesStore } from './pages/stores';
import './i18n';
import { boot_di } from './services/ioc';
import { EnrollmentPageStore } from './pages/EnrollmentPageStore';

boot_di();
const browserHistory = createBrowserHistory();
registerPagesStore();
const rootStore = Container.get(RootStore);
const enrollmentStore = Container.get(EnrollmentPageStore);
const history = syncHistoryWithStore(browserHistory, rootStore.routing);

ReactDOM.render(
    <Provider rootStore={rootStore} {...rootStore} enrollmentStore={enrollmentStore}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root') as HTMLElement,
);
