import React from 'react';
import { Container } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

export const UnknownError = () => {
    const { t } = useTranslation();
    const errorTitle = t('common:unknownError', 'Something went wrong on our end.');
    const action = t('common:refresh', 'refreshing the page.');
    const reload = () => window.location.reload();
    return (
        <Container className="unknown-error">
            <img src={`/assets/img/error.png`} className={'error-image'} />
            <div className="error-title">{errorTitle}</div>
            <div className="error-sub">
                <Trans i18nKey="common:tryAction" action={t('common:refresh', 'refreshing the page')}>
                    Try
                    <a href="#" onClick={reload} className="spot-link">
                        {{ action }}
                    </a>
                </Trans>
            </div>
        </Container>
    );
};
