import { EnrollmentFormValues } from '../../components/EnrollmentFormValues';
import { EnrollmentQuestionDto, EulaDto, SubmittedEnrollmentDto } from '../dtos';
import { SubmittedEnrollmentQuestionDto } from '../dtos/SubmittedEnrollmentQuestion.dto';
import { ContactTypes } from '../interfaces/contact.interface';

export class FormValuesToSubmittedValuesTransformer {
    transform(formValues: EnrollmentFormValues, rawEula: EulaDto, rawQuestions: EnrollmentQuestionDto[]): SubmittedEnrollmentDto {
        const questions: SubmittedEnrollmentQuestionDto[] = rawQuestions?.map(originalQuestion => ({
            questionID: originalQuestion.questionID,
            questionText: originalQuestion.questionText,
            questionAnswer: formValues[originalQuestion.questionText] || null,
        }));

        return {
            address: formValues.address1,
            address2: formValues.address2,
            city: formValues.city,
            contacts: [
                {
                    type: ContactTypes.TechnicalContact,
                    firstName: formValues.firstName,
                    lastName: formValues.lastName,
                    jobTitle: formValues.contactTitle,
                    phone: formValues.phoneNumber,
                    email: formValues.email,
                },
                {
                    type: ContactTypes.PracticeContact,
                    firstName: formValues.eulaFirstName,
                    lastName: formValues.eulaLastName,
                    jobTitle: formValues.eulaTitle,
                    phone: formValues.eulaPhone,
                    email: formValues.eulaEmail,
                },
            ],
            country: formValues.country,
            dataPointSiteID: formValues.dataPointSiteID,
            phone: formValues.eulaPhone,
            pims: formValues.practicePims,
            practiceID: formValues.practiceID,
            practiceName: formValues.practiceName,
            questions,
            sapID: formValues.sapID,
            signedEULA: {
                name: rawEula.name,
                signedBy: {
                    type: ContactTypes.ContractSigner,
                    firstName: formValues.eulaFirstName,
                    lastName: formValues.eulaLastName,
                    jobTitle: formValues.eulaTitle,
                    phone: formValues.eulaPhone,
                    email: formValues.eulaEmail,
                },
                type: rawEula.type,
                version: rawEula.version,
            },
            state: formValues.state,
            zip: formValues.postalCode,
        };
    }
}
