import { Service } from 'typedi';
import { RouterStore } from 'mobx-react-router';
import queryString from 'query-string';
import { observable } from 'mobx';

@Service()
export class RootStore {
    @observable pages: { [name: string]: any } = {};

    routing = new RouterStore();

    authToken: string | null;

    @observable enrollmentSlug: string;

    @observable programName: string;

    @observable partnerId: string;

    getAuthToken = (): string | null => {
        return this.authToken;
    };

    // TODO: This will likely be changed in the future; for now, in order to test and debug,
    // we will pass the required data as a url query parameters
    loadUserData = (): void => {
        const queryValues = queryString.parse(this.routing.location.search);
        //in short term, token support removed as part of https://jira.idexx.com/browse/EP-181. Should be protected in EP-191.
        //this.authToken = queryValues['token'] as string;
        this.partnerId = queryValues['partnerId'] as string;
        this.programName = queryValues['programName'] as string;
        this.enrollmentSlug = queryValues['enrollmentSlug'] as string;
    };
}
