import React from 'react';
import { FormikProps } from 'formik';
import { EnrollmentFormValues } from '../EnrollmentFormValues';
import classNames from 'classnames';

export interface RadioOption<T> {
    value: T;
    label: string;
}

export interface FormRadioGroupProps<> extends FormikProps<EnrollmentFormValues> {
    id: string;
    label: string;
    options: RadioOption<any>[];
}

export const FormRadioGroup = ({ id, label, options, values, errors, touched, setFieldValue }: FormRadioGroupProps) => {
    const radios: JSX.Element[] = options.map(option => {
        return (
            <label className="spot-form__radio" htmlFor={`${id}-${option.value}`} key={`${id}-${option.value}`}>
                <input
                    id={`${id}-${option.value}`}
                    name={`${id}-${option.value}`}
                    value={option.value}
                    type="radio"
                    className="spot-form__radio-input"
                    checked={values[id] === option.value}
                    onChange={() => setFieldValue(id, option.value)}
                />
                <span className="spot-form__radio-inner">
                    <span className="spot-form__radio-visual"></span>
                    <span className="spot-form__radio-label ">{option.label}</span>
                </span>
            </label>
        );
    });

    return (
        <div
            className={classNames('field', {
                'spot-form--error': errors[id] && touched[id],
            })}
        >
            <div className="spot-form__field-group spot-form__field-group--horizontal">
                <fieldset className="spot-form__radio-group radio-group-full-width" role="radiogroup">
                    <label className="spot-form__radio-group-label" htmlFor={id}>
                        {label}
                    </label>
                    <div className="spot-form__radio-group-inner">{radios}</div>
                    {errors[id] && touched[id] && (
                        <span className="spot-form__field-error" id="first-name-error" role="alert">
                            <span className="spot-form__field-error-text">{errors[id]}</span>
                        </span>
                    )}
                </fieldset>
            </div>
        </div>
    );
};
