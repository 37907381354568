import * as React from 'react';

interface SpotIconProps {
    type?: 'icon' | 'logos' | 'symbol';
    icon: string;
    title?: string;
    width?: string;
    height?: string;
    className?: string;
    [key: string]: any;
}

export class SpotSvg extends React.PureComponent<SpotIconProps> {
    static defaultProps = {
        type: 'icon',
    };
    render() {
        const { icon, title, type, ...rest } = this.props;
        let path = '';
        switch (type) {
            case 'icon':
                path = `/assets/spot/icons/spot_icons.svg#${icon}`;
                break;
            case 'symbol':
                path = `/assets/spot/symbols/spot_symbols.svg#${icon}`;
                break;
            default:
                path = `/assets/spot/${type}/${icon}`;
        }

        return (
            <svg {...rest}>
                {title && <title>{title}</title>}
                <use href={path} />
            </svg>
        );
    }
}
