import React from 'react';

export interface FormTitleProps {
    text: string;
    image?: string;
}

export const FormTitle = ({ text, image }: FormTitleProps) => {
    return (
        <div className="enrollment-app__title">
            {image && <img src={`/assets/img/${image}`} className={'enrollment-app__title-image'} />}
            <h1 className="title-text">{text}</h1>
        </div>
    );
};
