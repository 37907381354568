import { Service } from 'typedi';
import { action, computed, observable } from 'mobx';
import { EnrollmentService } from '../services/Enrollment.service';
import { RootStore } from '../store/RootStore';
import {
    EulaDto,
    SubmittedEnrollmentErrorsDto,
    EnrollmentQuestionDto,
    CompletedEnrollmentDto,
    SubmittedEnrollmentResponseDto,
    PimsTypeDto,
} from '../services/dtos';
import { FormValuesToSubmittedValuesTransformer } from '../services/transformers/FormValuesToSubmittedValues.transformer';
import { EnrollmentFormValues } from '../components/EnrollmentFormValues';
import { ApiError, SubmitApiError, SubmitBadRequestApiError, UnknownApiError } from '../services/error';
import { nonDataPointPims } from '../components/helpers/SupportedPimsOptions';

@Service()
export class EnrollmentPageStore {
    @observable isLoadingEnrollment = false;

    @observable isLoadingPims = false;

    @computed get isLoading() {
        return this.isLoadingEnrollment || this.isLoadingPims;
    }

    @observable isSubmitting = false;

    @observable isComplete = false;

    @observable error: ApiError;

    @observable rawEula: EulaDto;

    @observable questions: EnrollmentQuestionDto[] = [];

    @observable ReturnURL: String | null;

    @observable pimsTypes: PimsTypeDto[] = [];

    constructor(private readonly service: EnrollmentService, private readonly rootStore: RootStore) {}

    @action
    async checkEnrollment(): Promise<CompletedEnrollmentDto> {
        this.isLoadingEnrollment = true;
        try {
            const response = await this.service.checkForInitiatedEnrollment(
                this.rootStore.programName,
                this.rootStore.partnerId,
                this.rootStore.enrollmentSlug,
            );
            this.rawEula = response.signedEULA;
            this.questions = response.questions || [];
            this.ReturnURL = response.returnURL;
            if (this.rawEula.signedBy) {
                this.isComplete = true;
            }
            this.isLoadingEnrollment = false;
            return response;
        } catch (error) {
            this.error = new UnknownApiError(error.message || 'Unknown API error');
            this.isLoadingEnrollment = false;
            throw this.error;
        }
    }

    @action
    async submitEnrollment(values: EnrollmentFormValues): Promise<SubmittedEnrollmentResponseDto | SubmittedEnrollmentErrorsDto> {
        this.isSubmitting = true;
        const transformer = new FormValuesToSubmittedValuesTransformer();
        const requestData = transformer.transform(values, this.rawEula, this.questions);
        try {
            const response = await this.service.submitEnrollment(
                this.rootStore.programName,
                this.rootStore.partnerId,
                this.rootStore.enrollmentSlug,
                requestData,
            );
            if ((response as SubmittedEnrollmentResponseDto).returnURL) {
                this.ReturnURL = (response as SubmittedEnrollmentResponseDto).returnURL;
            } else if ((response as SubmittedEnrollmentResponseDto).partnerReturnURL) {
                this.ReturnURL = (response as SubmittedEnrollmentResponseDto).partnerReturnURL;
            }

            this.isSubmitting = false;
            this.isComplete = true;
            return response;
        } catch (error) {
            if (error.status === 400) {
                const response: SubmittedEnrollmentErrorsDto = error?.innerException?.response?.data || {};
                this.error = new SubmitBadRequestApiError(error.message || 'Submit error occurred', response);
            } else {
                this.error = new SubmitApiError(error.message || 'Submit error occurred');
            }

            this.isSubmitting = false;
            throw this.error;
        }
    }

    @action
    async loadPimsTypes(): Promise<void> {
        try {
            this.isLoadingPims = true;
            if (this.rootStore.programName.localeCompare('datapoint', undefined, { sensitivity: 'base' }) === 0) {
                this.pimsTypes = await this.service.getDataPointPimsTypes();
            } else {
                this.pimsTypes = nonDataPointPims;
            }
        } catch (error) {
            this.error = new UnknownApiError(error.message || 'Unknown DataPoint PIMS API error');
            throw this.error;
        } finally {
            this.isLoadingPims = false;
        }
    }
}
