import { CompletedEnrollmentDto } from '../dtos';
import { EnrollmentFormValues } from '../../components/EnrollmentFormValues';

export class PrefilledValuesToFormValuesTransformer {
    transform(values: CompletedEnrollmentDto): EnrollmentFormValues {
        const technicalContact = values.contacts?.find(contact => contact.type === 'TechnicalContact');

        return {
            address1: values.address || '',
            address2: values.address2 || '',
            city: values.city || '',
            contactTitle: technicalContact?.jobTitle || '',
            country: values.country || 'USA',
            email: technicalContact?.email || '',
            eulaEmail: values.signedEULA?.signedBy?.email || '',
            eulaFirstName: values.signedEULA?.signedBy?.firstName || '',
            eulaLastName: values.signedEULA?.signedBy?.lastName || '',
            eulaIsAccepted: false,
            eulaIsAuthorized: false,
            eulaPhone: values.signedEULA?.signedBy?.phone || '',
            eulaTitle: values.signedEULA?.signedBy?.jobTitle || '',
            firstName: technicalContact?.firstName || '',
            lastName: technicalContact?.lastName || '',
            phoneNumber: technicalContact?.phone || '',
            postalCode: values.zip || '',
            practiceName: values.practiceName || '',
            practicePims: values.pims || '',
            state: values.state || '',
            sapID: values.sapID || null,
            practiceID: values.practiceID || null,
            dataPointSiteID: values.dataPointSiteID || null,
        };
    }
}
