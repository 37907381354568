import * as React from 'react';
import classNames from 'classnames';
import { SpotSvg } from './SpotSvg';

export interface SpotPillProps {
    type?: 'primary' | 'secondary' | 'positive' | 'negative' | 'warning';
    icon?: string;
    isSmall?: boolean;
    isOutline?: boolean;
    iconPosition?: 'left' | 'right';
    children: any;
    onClick?: any;
    color?: string;
}

export class SpotPill extends React.PureComponent<SpotPillProps> {
    static defaultProps: SpotPillProps = {
        type: 'secondary',
        children: null,
        iconPosition: 'left',
    };

    render() {
        const { type, children, iconPosition, icon, isOutline, isSmall, color } = this.props;
        const style = color ? { backgroundColor: color, borderColor: color } : {};
        return (
            <span
                className={classNames(`spot-pill spot-pill--${type}`, { 'spot-pill--outline': isOutline }, { 'spot-pill--small': isSmall })}
                style={{ ...style, ...{ margin: '3px' } }}
                onClick={this.props.onClick}
            >
                {icon && iconPosition === 'left' && (
                    <SpotSvg icon={icon} className="spot-pill__icon spot-pill__icon--left" width="12px" height="12px" />
                )}
                {children}
                {icon && iconPosition === 'right' && (
                    <SpotSvg icon={icon} className="spot-pill__icon spot-pill__icon--right" width="12px" height="12px" />
                )}
            </span>
        );
    }
}
