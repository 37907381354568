import React, { Component } from 'react';
import { Formik, FormikProps } from 'formik';
import { Container } from 'react-bootstrap';
import { PracticeStep } from '../pages/steps/PracticeStep';
import { EnrollmentFormFooter } from './EnrollmentFormFooter';
import * as Yup from 'yup';
import { ContactStep } from '../pages/steps/ContactStep';
import { getPracticeValidationSchema, getContactValidationSchema, getEulaValidationSchema } from '../pages/steps/ValidationSchemas';
import { EulaStep, EulaFooterElements } from '../pages/steps/EulaStep';
import { ErrorFocus } from './ErrorFocus';
import { scrollToTop } from './helpers/ScrollToTop';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ValidatedStep } from '../pages/steps/ValidatedStep';
import { inject, observer } from 'mobx-react';
import { EnrollmentPageStore } from '../pages/EnrollmentPageStore';
import { EnrollmentFormValues } from './EnrollmentFormValues';
import { PrefilledValuesToFormValuesTransformer } from '../services/transformers/PrefilledValuesToFormValues.transformer';
import { Loading } from './Loading';
import { UnknownError } from './UnknownError';
import { ApiErrorType } from '../services/error';

export interface EnrollmentFormStateInterface {
    brandedImage: string;
    currentStepIndex: number;
    totalStepCount: number;
    validateOnChange: boolean;
    inError: boolean;
}

@observer
class EnrollmentForm extends Component<WithTranslation & { enrollmentStore: EnrollmentPageStore }, EnrollmentFormStateInterface> {
    initialFormValues: EnrollmentFormValues;

    orderedSteps: ValidatedStep<EnrollmentFormValues>[] = [];

    state: EnrollmentFormStateInterface = {
        brandedImage: 'idexx_logo.svg',
        currentStepIndex: 0,
        inError: false,
        totalStepCount: 0,
        validateOnChange: false,
    };

    prevStep = (props: FormikProps<EnrollmentFormValues>): void => {
        this.setState((state: EnrollmentFormStateInterface) => ({
            currentStepIndex: Math.max(state.currentStepIndex - 1, 0),
            validateOnChange: false,
        }));
        props.setTouched({});
        scrollToTop();
    };

    nextStep = (props: FormikProps<EnrollmentFormValues>): Promise<void> => {
        this.setState({ validateOnChange: true });
        return props.submitForm();
    };

    handleSubmit = async (values: EnrollmentFormValues, formikbag): Promise<void> => {
        const { currentStepIndex, totalStepCount } = this.state;
        if (currentStepIndex < totalStepCount - 1) {
            // Next Page
            this.setState((state: EnrollmentFormStateInterface) => ({
                currentStepIndex: state.currentStepIndex + 1,
                validateOnChange: false,
            }));
            formikbag.setTouched({});
            scrollToTop();
        } else {
            // Submit the form
            try {
                const response = await this.props.enrollmentStore.submitEnrollment(values);

                // TODO: remove QA log statement, eventually
                console.log(response);
            } catch (error) {
                // TODO: remove QA log statement, eventually
                console.log('Submit error occurred', error);
            }
        }
    };

    async componentDidMount(): Promise<void> {
        try {
            const [prefilledData] = await Promise.all([this.props.enrollmentStore.checkEnrollment(), this.props.enrollmentStore.loadPimsTypes()]);

            // prefill any form values
            const transformer = new PrefilledValuesToFormValuesTransformer();
            this.initialFormValues = {
                ...transformer.transform(prefilledData),
                ...this.props.enrollmentStore.questions.reduce((agg, question) => {
                    agg[question.questionText] = null;
                    return agg;
                }, {}),
            };

            this.orderedSteps = [
                { step: PracticeStep, schema: getPracticeValidationSchema(this.props.t) },
                {
                    step: ContactStep,
                    schema: getContactValidationSchema(this.props.t, this.props.enrollmentStore.questions),
                },
                { step: EulaStep, schema: getEulaValidationSchema(this.props.t), extraFooterElement: EulaFooterElements },
            ];

            this.setState({ totalStepCount: this.orderedSteps.length });
        } catch (error) {
            this.setState({ inError: true });
        }
    }

    render() {
        if (this.props.enrollmentStore.error?.type === ApiErrorType.UNKNOWN || this.state.inError) {
            return <UnknownError />;
        }
        if (this.props.enrollmentStore.isLoading || this.state.totalStepCount === 0) {
            return <Loading text={this.props.t('common:loading', 'Loading...')} />;
        }
        if (this.props.enrollmentStore.isComplete) {
            if (this.props.enrollmentStore.ReturnURL) {
                window.location.assign(this.props.enrollmentStore.ReturnURL.toString());
            }
            return <Loading text={this.props.t('common:loading', 'Loading...')} />;
        }
        const { currentStepIndex, totalStepCount, brandedImage, validateOnChange } = this.state;
        const validationSchema: Yup.ObjectSchema = this.orderedSteps[currentStepIndex].schema;
        const CurrentStep = this.orderedSteps[currentStepIndex].step;
        const FooterElement = this.orderedSteps[currentStepIndex].extraFooterElement;
        const apiError = this.props.enrollmentStore.error;
        return (
            <Container className="enrollment-app">
                {this.props.enrollmentStore.isSubmitting && <Loading asOverlay={true} />}
                <Formik
                    initialValues={this.initialFormValues}
                    onSubmit={this.handleSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={validateOnChange}
                    validateOnBlur={validateOnChange}
                    enableReinitialize
                >
                    {props => {
                        return (
                            <form autoComplete="off" className="enrollment-form">
                                <ErrorFocus {...props} apiError={apiError} />
                                <div className="enrollment-form-content">
                                    <CurrentStep {...props} />
                                </div>
                                <EnrollmentFormFooter
                                    {...props}
                                    totalSteps={totalStepCount}
                                    currentStepIndex={currentStepIndex}
                                    brandedImage={brandedImage}
                                    onNext={this.nextStep}
                                    onBack={this.prevStep}
                                >
                                    {FooterElement && <FooterElement {...props} />}
                                </EnrollmentFormFooter>
                            </form>
                        );
                    }}
                </Formik>
            </Container>
        );
    }
}

export default inject('enrollmentStore')(withTranslation()(EnrollmentForm));
