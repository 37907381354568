import * as React from 'react';
import classNames from 'classnames';
import { SpotSvg } from '../SpotSvg';

export interface SpotDropdownProps {
    title: string;
    titleClassName?: string;
    titleIconClassName?: string;
    elements: any[];
    onChange: any;
    wrapText?: boolean;
}

interface State {
    isOpen: boolean;
}

export class SpotDropdown extends React.PureComponent<SpotDropdownProps, State> {
    private readonly wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
    static defaultProps = {
        wrapText: true,
    };
    state = {
        isOpen: false,
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        const { title, titleClassName, titleIconClassName, elements, wrapText } = this.props;

        return (
            <div className="spot-dropdown" style={{ display: 'inline-block' }} ref={this.wrapperRef}>
                <span onClick={this.openClose} className={titleClassName}>
                    {title}
                    <SpotSvg
                        icon={this.state.isOpen ? 'caret-up' : 'caret-down'}
                        className={titleIconClassName}
                        style={{ width: '12px', height: '12px', marginLeft: '5px', marginBottom: '2px' }}
                    />
                </span>

                <ul className="spot-dropdown__content" style={{ display: this.state.isOpen ? 'block' : 'none' }}>
                    {elements.map(e => {
                        const classes = classNames({
                            'spot-dropdown__content-item': true,
                            'spot-dropdown__content--divider': e.isDivider,
                            'spot-dropdown__content-item--single-line': !wrapText,
                        });
                        return (
                            <li key={e.id} value={e.id} className={classes} onClick={this.onChange}>
                                {e.value}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    private openClose = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    private onChange = event => {
        const targetId = event.target.getAttribute('value');
        this.props.onChange(targetId);
        this.openClose();
    };

    private handleClickOutside = event => {
        const { isOpen } = this.state;
        if (isOpen && this.wrapperRef && !this.wrapperRef.current!.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    };
}
