import * as React from 'react';
import Griddle, { ColumnDefinition, connect, GriddleStyleConfig, plugins, RowDefinition } from 'griddle-react';
import * as _ from 'lodash';

// export interface CellProps {
//     griddleKey?: number;
//     columnId?: string;
//     value?: any;
//     onClick?: React.MouseEventHandler<Element>;
//     onMouseEnter?: React.MouseEventHandler<Element>;
//     onMouseLeave?: React.MouseEventHandler<Element>;
//     className?: string;
//     style?: React.CSSProperties;
// }
interface SpotDataTableProps {
    data: any[];
    columns: ColumnDefinitionProps[];
    styleConfig?: GriddleStyleConfig;
}

const rowDataSelector = (state, { griddleKey }) => {
    return state
        .get('data')
        .find(rowMap => rowMap.get('griddleKey') === griddleKey)
        .toJSON();
};

const enhancedWithRowData = connect((state, props) => {
    return {
        rowValue: rowDataSelector(state, props),
    };
});

/**
 * @see http://griddlegriddle.github.io/Griddle/docs/
 */
export class SpotDataTable extends React.Component<SpotDataTableProps> {
    static defaultProps = {
        styleConfig: {
            classNames: {
                Table: 'spot-data-table spot-data-table--sortable',
                TableHeadingCell: '',
                TableHeadingCellAscending: 'spot-data-table__col--sort-ascending',
                TableHeadingCellDescending: 'spot-data-table__col--sort-descending',
            },
        },
    };

    static fulltextFilter(items: any[], searchTerm?: string) {
        return items.filter(item => {
            if (!searchTerm) {
                return true;
            }
            return _.values(item).reduce((prev, curr) => {
                return (
                    prev ||
                    (!!curr &&
                        curr
                            .toString()
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()))
                );
            }, false);
        });
    }

    render() {
        const { data, columns, styleConfig } = this.props;
        return (
            <Griddle
                styleConfig={styleConfig}
                enableSettings={false}
                data={data}
                plugins={[plugins.LocalPlugin]}
                components={{
                    Layout: ({ Table, Pagination, Filter }) => (
                        <div>
                            <Table />
                            <Pagination />
                        </div>
                    ),
                }}
            >
                <RowDefinition>
                    {columns.map(p => {
                        const { customComponent, ...props } = p;
                        if (customComponent) {
                            return <ColumnDefinition customComponent={enhancedWithRowData(customComponent)} key={props.id} {...props} />;
                        }
                        return <ColumnDefinition key={props.id} {...props} />;
                    })}
                </RowDefinition>
            </Griddle>
        );
    }
}

interface ColumnDefinitionProps {
    // The name of the column that this definition applies to.
    id: string;
    // The css class name, or a function to generate a class name from props, to apply to the header for the column.
    headerCssClassName?: string | ((props: any) => string);
    // The css class name, or a function to generate a class name from props, to apply to this column.
    cssClassName?: string | ((props: any) => string);
    // The display name for the column. This is used when the name in the column heading and settings should
    // be different from the data passed in to the Griddle component.
    title?: string;
    // The component that should be rendered instead of the standard column data. This component will still
    // be rendered inside of a TD element.
    customComponent?: any;
    // The component that should be used instead of the normal title
    customHeadingComponent?: any;
    // Can this column be filtered
    filterable?: boolean;
    // Can this column be sorted
    sortable?: boolean;
    // What sort method this column uses
    sortMethod?: (data: any[], column: string, sortAscending?: boolean) => number;
    // The width of this column -- this is string so things like % can be specified
    width?: number | string;
    // Is this column visible
    visible?: boolean;
    // Allow custom plugin props
    [x: string]: any;
}
