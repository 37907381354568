import React from 'react';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { EnrollmentFormValues } from '../EnrollmentFormValues';
import { SpotSvg } from '@enrollment/spot';

export interface FormDropdownFieldProps extends FormikProps<EnrollmentFormValues> {
    id: string;
    label: string;
    small: boolean;
}

/**
 * For fields expecting only a single true or false value
 */
export const FormSingleCheckboxField = ({ id, label, values, handleChange, handleBlur, errors, touched, small = false }: FormDropdownFieldProps) => {
    return (
        <div
            className={classNames('field', 'single-check-field', {
                'spot-form--error': errors[id] && touched[id],
            })}
        >
            <label
                className={classNames('spot-form__checkbox', {
                    'spot-form__checkbox--small': small,
                })}
                htmlFor={id}
            >
                <input
                    id={id}
                    type="checkbox"
                    value={values[id]}
                    onChange={handleChange}
                    checked={values[id]}
                    onBlur={handleBlur}
                    className={classNames('single-check-input', 'spot-form__checkbox-input', {
                        error: errors[id] && touched[id],
                    })}
                />
                <span className="spot-form__checkbox-inner">
                    <span className="spot-form__checkbox-visual">
                        <SpotSvg icon={'checkmark'} className="spot-icon spot-form__checkbox-checked-icon" />
                    </span>
                    <span className="spot-form__checkbox-label">{label}</span>
                </span>
            </label>
            {errors[id] && touched[id] && (
                <span className="spot-form__field-error" id="first-name-error" role="alert">
                    <span className="spot-form__field-error-text">{errors[id]}</span>
                </span>
            )}
        </div>
    );
};
